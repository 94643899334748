.mydashboard-main-div {
  overflow: auto;
}

.mydashboard-top-left-card span,
.mydashboard-top-middle-card span {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.mydashboard-top-clock-title img,
.mydashboard-top-middle-clock-title img {
  width: 18px;
  height: 20px;
  object-fit: cover;
}

.mydashboard-top-clock-title h6,
.mydashboard-top-middle-clock-title h6 {
  font-size: 15px;
  font-weight: 500;
  color: #3fc28a;
  margin: 0;
}

.mydashboard-to-right-attendance h6 {
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.mydashboard-to-right-attendance .mydashboard-to-right-attendance-startTime {
  font-size: 30px;
  font-weight: 600;
  color: #000000;
  min-width: 150px;
  text-align: center;
}

.mydashboard-to-right-attendance .mydashboard-to-right-attendance-date {
  font-size: 14px;
  font-weight: 400;
  color: #00000090;
}

.mydashboard-to-right-attendance>button {
  display: flex;
  gap: 10px;
  padding: 4px 12px;
  border-radius: 5px;
  background-color: #3fc28a;
  width: 105px;
  justify-content: center;
  align-items: center;
  /* justify-content: space-between; */
  border: transparent;
  color: #fff;
  font-size: 14.5px;
}

.mydashboard-to-right-attendance>div>button {
  font-size: 14px;
  font-weight: 400;
  color: #00000090;
  border: none;
  background-color: transparent;
}

/* .mydashboard-to-right-attendance > div > button > img {
  width: 15px;
  height: 17px;
  object-fit: cover;
} */
/* .mydashboard-top-right-card */
.mydashboard-to-right-time>div {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 160px;
}

.mydashboard-to-right-time>div :first-child {
  font-size: 14px;
  font-weight: 600px;
  color: #000;
}

.mydashboard-to-right-time>div :last-child {
  font-size: 14px;
  font-weight: 400px;
  color: #00000085;
}

.mydashboard-middle-left .apexcharts-title-text,
.mydashboard-middle-left .apexcharts-toolbar {
  display: none;
}

.mydashboard-middle-left .apexcharts-bar-area {
  border-radius: 6px 6px 0 0;
}

.mydashboard-middle-section,
.mydashboard-bottom-section {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

/* .mydashboard-middle-right {
  width: 49%;
} */

.mydashboard-middle-section .mydashboard-middle-right {
  width: 100%;
}

.myreportees-leave-apply-btn {
  background-color: #3fc28a !important;
  width: 150px !important;
  color: #fff;
  font-weight: 500;
}

.mydashboard-middle-left .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center {
  display: none;
}

/* .mydashboard-last-fifteen-days{
  font-size: 15px;
  border: 1px solid #a2a1a833;
  padding: 6px 16px;
  border-radius: 10px;
  background-color: rgba(217, 217, 217, 0.2);
  margin: 0;
} */
.mydashboard-attendance-table {
  border: 1px solid #eff0f6;
  border-radius: 20px;
  width: inherit;
  padding: 15px;
  position: relative;
}

.mydashboard-leave-overview {
  height: 290px;
  padding-right: 4px;
}