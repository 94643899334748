.myreportees-main-div {
  width: 100%;
  /* height: "400px", */
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.myreportees-main-div::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.myreportees-line-chart-div-container {
  display: flex;
  flex-direction: row;
  /* border:1px solid black; */
  width: 100%;
  justify-content: space-between;
  gap: 12px;
}

.myreportees-table-chart-div-container {
  display: flex;
  flex-direction: row;
  /* border:1px solid black; */
  width: 100%;
  justify-content: space-between;
  gap: 12px;
}

.myreportees-line-chart-div {
  height: 140px;
  border: 1px solid #eff0f6;
  border-radius: 20px;
  /* width:30%; */
  width: inherit;
}

.myreportees-table-chart-div {
  height: 350px;
  border: 1px solid #eff0f6;
  border-radius: 20px;
  width: inherit;
  padding: 15px;
  position: relative;
}

.myreportees-table-chart-attendance-overview-container {
  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
  width: 100%;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
}

.myreportees-table-attendance-overview {
  height: 290px;
  /* overflow-y: auto; */
  padding-right: 4px;
}

/* 
.reportees-timesheet-list-table-container {
  height: 320px;
  padding-right: 4px;
}

.myreportees-table-attendance-overview::-webkit-scrollbar,
.reportees-timesheet-list-table-container::-webkit-scrollbar {
  width: 4px;
  border-radius: 8px;
}

.myreportees-table-attendance-overview::-webkit-scrollbar-track,
.reportees-timesheet-list-table-container::-webkit-scrollbar-track {
  background-color: rgba(159, 159, 159, 0.5);
  border-radius: 8px;
}

.myreportees-table-attendance-overview::-webkit-scrollbar-thumb,
.reportees-timesheet-list-table-container::-webkit-scrollbar-thumb {
  background-color: #747474;
  border-radius: 8px;
  width: 5px;
  background-clip: content-box;
  border: 2px solid transparent;
} */

.myreportees-table-attendance-overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* margin: 5px 0; */
}

.myreportees-table-attendance-overview-header button,
.myreportees-leave-overview-section button {
  font-size: 14.5px;
  border: 1px solid #a2a1a833;
  padding: 6px;
  border-radius: 10px;
  width: 80px;
  background-color: white;
}

/* .myreportees-name-ans-image {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #a2a1a833;
} */
.reportees-views-list-table th {
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}

.reportees-views-list-table tr {
  border-bottom: 1px solid #a2a1a833;
}

.reportees-views-list-table th,
.reportees-views-list-table td {
  padding: 4px 10px 4px 0px;
  margin: 0;
  font-size: 14px;
}

.reportees-views-list-table td {
  font-weight: 300;
}

/* .reportees-views-list-table td:last-child{
  font-weight: 500;
} */

/* .myreportees-name-ans-image img,
.myreportees-leave-overview img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  margin-right: 10px;
} */
.myreportees-name-ans-image {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}
.myreportees-name-ans-image td {
  font-size: 14px;
  color: #000000;
}

.myreportees-attendance-status-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 85px;
  height: 28px;
  font-weight: 500 !important;
  border-radius: 4px;
  margin-top: 10px !important;
  padding: 0 !important;
}

.myreportees-table-chart-div .apexcharts-legend-series .apexcharts-legend-text {
  color: #1d2129 !important;
  font-weight: 600 !important;
  margin-right: 5px;
}

.myreportees-table-chart-div .apexcharts-legend-series .apexcharts-legend-marker {
  border-radius: 30px !important;
}

.myreportees-table-chart-div .apexcharts-legend {
  top: 12px !important;
  /* bottom: -1px !important; */
}

.myreportees-table-chart-div .apexcharts-canvas .apexcharts-toolbar {
  display: none;
}

.myreportees-leave-overview-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 0; */
}

.myreportees-leave-overview {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  /* position: relative; */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.dashboard-leave-overview-table th, .dashboard-leave-overview-table td {
  padding: 4px 10px 4px 0px;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
  font-size: 14px;
  height: 47px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.dashboard-leave-overview-table th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
  font-weight: 500;
}
.dashboard-leave-overview-table tr > :first-child{
  padding: 4px 15px 4px 0;
}

/* .myreportees-attendance-overview-container{
  display: flex;
  gap: 4px;
} */
/* .myreportees-attendance-overview-container p {
  font-size: 16px;
  font-weight: 400;
}

.myreportees-attendance-overview-container p span {
  font-size: 17px;
  font-weight: 600;
} */

/* For Tablet */
@media screen and (max-width: 768px) {
  .myreportees-main-div {
    height: calc(100vh - 170px);
  }
}

/* .dashboard_myreportees_select-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid var(--Gray---11);
    height: 44px;
    padding: 7px 16px;
    border-radius: 8px;
    width: 255px;
  }
  .dashboard_myreportees_select-content label {
    font-size: var(--font-small-size);
    color: var(--Gray---3);
  } */

.dashboard_myreportees_select__indicators {
  display: none;
}

.dashboard_myreportees_select__control {
  border: 0;
  border-radius: 8px;
  background-color: transparent !important;
  box-sizing: border-box !important;
  gap: 5px !important;
  width: 140px;
  cursor: pointer !important;
  /* padding: 2px 6px !important; */
  padding: 0 !important;
}

.dashboard_myreportees_select__control--is-focused {
  box-shadow: none !important;
  padding: 0 !important;
}

.dashboard_myreportees_select__value-container {
  padding: 0 !important;
}

.dashboard_myreportees_select__placeholder {
  color: var(--Black---2) !important;
  font-family: var(--font-primary-family) !important;
  font-size: var(--font-normal-size) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  text-wrap: nowrap;
}

.dashboard_myreportees_select__single-value {
  color: var(--Black---2) !important;
  font-family: var(--font-primary-family) !important;
  font-size: var(--font-normal-size) !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.dashboard_myreportees_select__menu {
  background: var(--White) !important;
  border-radius: 3px !important;
  border: 0.75px solid var(--Black---2) !important;
  width: 100%;
}

.dashboard_myreportees_select__option {
  color: var(--Black---2) !important;
  font-family: var(--font-primary-family);
  font-size: var(--font-small-size) !important;
  font-weight: 400;
  cursor: pointer;
  border-bottom: 2px solid white;
}

.dashboard_myreportees_select__option--is-selected,
.dashboard_myreportees_select__option--is-focused {
  background-color: var(--Blue---1) !important;
  color: var(--White) !important;
  padding: 0 !important;
}

/* For Mobile */
@media screen and (max-width: 480px) {
  .myreportees-line-chart-div-container {
    flex-direction: column;
  }

  .myreportees-line-chart-div {
    width: 100%;
  }

  .myreportees-table-chart-div-container {
    flex-direction: column;
  }

  .myreportees-table-chart-div {
    width: 100%;
  }

  .myreportees-main-div {
    height: calc(100vh - 170px);
  }
}