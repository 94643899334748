.profile-main {
  border: 1px solid rgba(162, 161, 168, 0.2);
  border-radius: 10px;
  /* opacity: 0px; */
  width: 97%;
  margin: 0 auto;
  margin-top: 2px;
}

/* Profile */
.my-profile-content-personal {
  padding: 12px 20px 30px 20px;
  border-radius: 10px;
}

.my-profile-content {
  padding: 25px 20px 30px 20px;
  border-radius: 10px;
}
