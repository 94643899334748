.asset-list {
    height: max-content;
}

.asset-list-container {
    width: 97%;
    margin: 0 auto;
    margin-top: 2px;
}

.asset-list-banner {
    width: 100%;
    padding: 12px 10px 15px 0px;
    border-radius: 10px;
    border: 1px solid #00000033;
}

/* Top section */

.asset-list-top-column {
    display: flex;
    gap: 15px;
    margin-left: 5px;
}

.accet-name-filter .asset-name {
    width: 227px;
    height: 40px;
    border: 1px solid #00000033;
    border-radius: 10px;
}

.accet-name-filter .asset-name .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    padding: 2px 12px;
}

.accet-name-filter .asset-name .p-icon.p-dropdown-clear-icon {
    top: 11px;
    right: 40px;
}

.accet-type-filter .asset-types {
    width: 190px;
    height: 40px;
    border: 1px solid #00000033;
    border-radius: 10px;
}

.accet-type-filter .asset-types .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    padding: 2px 12px;
}

.accet-type-filter .asset-types .p-icon.p-dropdown-clear-icon {
    top: 11px;
    right: 40px;
}


.accet-serial-filter .accet-serial {
    width: 227px;
    height: 40px;
    border: 1px solid #00000033;
    border-radius: 10px;
}

.accet-serial-filter .accet-serial .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    padding: 2px 12px;
}

.accet-serial-filter .accet-serial .p-icon.p-dropdown-clear-icon {
    top: 11px;
    right: 40px;
}


.asset-add-button {
    width: 190px;
    height: 40px;
    padding: 5px;
    border-radius: 10px;
    background: #3FC28A;
    color: #FFFFFF;
    border: none;
    font-family: "Lexend";
    font-size: 16px;
    font-weight: 500;
}

.asset-add-icon {
    margin-right: 10px;
    position: relative;
    bottom: 1px;
}

/* Table section */

.asset-list-table-container {
    margin-top: 20px;
    max-height: calc(100Vh - 243px);
    overflow-y: auto;
    padding-left: 25px;
}

.asset-list-table-container::-webkit-scrollbar {
    width: 2px;
}

.asset-list-table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.asset-list-table-container::-webkit-scrollbar-thumb {
    background: #888;
}

.asset-list-table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.asset-list-table-container th {
    position: sticky;
    top: 0px;
    background-color: #ffffff;
    z-index: 2;
}

.asset-list-table {
    width: 100%;
    border-collapse: collapse;
}

.asset-list-table th {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #000000;
}

.asset-list-table td {
    font-size: 16px;
    height: 47px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    vertical-align: middle;
    font-family: "Lexend";
}

.asset-list-table th,
.asset-list-table td {
    padding: 4px 10px 4px 0px;
    margin: 0;
    border-bottom: 1px solid #a2a1a833;
}

.accet-action>span {
    cursor: pointer;
}

.asset-list-warranty {
    height: 24px;
    width: 50px;
    padding: 3px, 8px;
    text-align: center;
    border-radius: 4px;
    font-weight: 500;
}

/* Record showing*/

.asset-record-list>span {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    color: #000000;
}

.asset-record-list>div>select {
    height: 40px;
    width: 60px;
    padding: 10px;
    border-radius: 8px;
    border: Mixed solid #00000033;
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    background: transparent;
    cursor: pointer;
}

.asset-record-list>div>select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.asset-record-list-icon {
    position: absolute;
    right: 6px;
    top: 8px;
    pointer-events: none;
}

/* pagenation */

.asset-pagination {
    padding: 0;
    margin: 0;
    float: right;
}

.asset-pagination li {
    display: inline-block;
    margin-right: 8px;
}

.asset-pagination li button,
.asset-pagination li span {
    border: none !important;
    background: none !important;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none !important;
}

.asset-pagination-container {
    height: 56px;
}

.asset-pagination>ul>li>button {
    padding: 0 !important;
    width: 31px !important;
    height: 32px !important;
    border-radius: 5px !important;
    background: none !important;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding: 5px 8px;
    font-weight: 300 !important;
    gap: 10px;
    border-radius: 8px;
}

.asset-pagination>ul>li[aria-current="page"]>button {
    color: rgba(63, 194, 138, 1) !important;
    border: 1px solid rgba(63, 194, 138, 1) !important;
    text-align: center;
}

/* Modal Section */

.asset-modal-title {
    font-family: "Lexend";
    font-size: 24px;
    font-weight: 600;
}

.Asset-cancle-button {
    width: 150px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 20px;
    font-weight: 400;
    font-family: "Lexend", sans-serif;
    background: none;
}

.asset-add-buton {
    width: 150px;
    height: 50px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 400;
    font-family: "Lexend", sans-serif;
    background: #3fc28a;
}

/* field Section */
.asset-form-item {
    position: relative;
}

.asset-form-item .asset-modal-label {
    position: absolute;
    top: 0;
    left: 0;
    color: black;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    transform: translate(25px, 10px);
}

.asset-form-item .asset-modal-labels {
    position: absolute;
    top: 0;
    left: 0;
    color: black;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    transform: translate(25px, 28px);
}

.asset-form-item .asset-modal-input:focus~.asset-modal-label,
.asset-form-item .asset-modal-input:focus~.asset-modal-labels,
.asset-form-item .asset-modal-input:not(:placeholder-shown)~.asset-modal-labels,
.asset-form-item .asset-modal-input:not(:placeholder-shown)~.asset-modal-label {
    color: black;
    font-weight: 300;
    font-size: 16px;
    transform: translate(12px, -26px);
}

.asset-modal-input {
    width: 100%;
    font-size: 16px;
    height: 50px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    color: black;
    border: none;
    border-radius: 10px;
    text-wrap: wrap;
    object-fit: cover;
}

.asset-modal-input:focus {
    box-shadow: none;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.p-float-label-asset input {
    padding-right: 140px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    background-color: #0000000d;
    color: black;
    padding-left: 10px;
    margin-left: none !important;
    height: 48px !important;
}

.asset-calendar .p-icon {
    margin-right: 40px;
}

.asset-date-picker-lable {
    position: absolute;
    left: 0;
    margin-left: 10px;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-top: 12px;
    pointer-events: none;
}

.p-float-label-asset input:focus~label,
.p-float-label-asset .p-inputwrapper-filled~label {
    margin-top: -22px;
    margin-left: 2px;
    font-weight: 300;
}

.asset-calendar.filled-bg input:disabled {
    font-family: Lexend;
    font-size: 16px;
    height: 48px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 1) !important;
    border: 1px solid rgba(0, 0, 0, 0.5);
    opacity: 1;
}

/* Modal dropdown */

.select-container-asset {
    position: relative;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.05);
    text-wrap: wrap;
    object-fit: cover;
    outline: none;
    transition: all 0.2s ease-in-out;
    color: black;
    border: none;
    border-radius: 10px;
    height: 50px;
}


.select-container-asset:focus {
    box-shadow: none !important;
    outline: none;
    border: 1px solid black;
    background-color: white !important;
    height: 48px;

}

.p-errors {
    color: red;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    margin-left: 10px;
    margin-top: -10px;
}

/* tril */

.floating-label-asset {
    position: absolute;
    margin: 0 auto;
    font-size: 16px;
    color: black;
    transition: all 0.2s ease-in-out;
    transform: translate(12px, 10px);
  }
  
  .active .floating-label-asset {
    position: absolute;
    margin-top: -35px;
    font-size: 16px;
    color: black;
    margin-left: -10px;
    font-weight: 300;
  }



/* Media Query Section */

@media (max-width: 1170px) {
    .asset-list-top-column {
        max-width: 100%;
        display: block;
    }

    .accet-name-filter,
    .accet-type-filter,
    .accet-serial-filter,
    .asset-add-button {
        width: 100%;
        margin-bottom: 15px;
    }

    .asset-list-table-container {
        margin-top: 20px;
        max-height: calc(100Vh - 424px);
        overflow-y: auto;
        padding-left: 25px;
    }

    .accet-name-filter .asset-name,
    .accet-serial-filter .accet-serial,
    .accet-type-filter .asset-types {
        width: 100%;
    }


}

@media (max-width: 1180px) {
    .asset-list-table-container {
        margin-top: 20px;
        max-height: calc(100Vh - 245px);
        overflow-y: auto;
        padding-left: 25px;
    }


}