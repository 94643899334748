.custom-calendar0.filled-bg input:disabled {
  font-family: Lexend;
  font-size: 16px;
  height: 48px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 1) !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.add-professional-container{
  margin-top: -5px;
  row-gap: 20px !important;
}

/* .add-box .floating-label-datepicker .p-float-label-personal .p-button {
  color: black;
  background: none;
  position: absolute;
  left: 90%;
  top: 25%;
  border: none;
  opacity: 1;
} */

@media screen and (max-width: 768px) {
  .add-box{
    margin-bottom: 20px;
  }
  .add-professional-container{
    margin-top: 10px;
  } 
  /* .add-box .floating-label-datepicker .p-float-label-personal .p-button {
    color: black;
    background: none;
    position: absolute;
    left: 88%;
    top: 25%;
    border: none;
    opacity: 1;
  } */
}
@media screen and (max-width: 1040px) {
  .add-box .floating-label-datepicker .p-float-label-personal .p-button {
    color: black;
    background: none;
    position: absolute;
    left: 86%;
    top: 25%;
    border: none;
    opacity: 1;
  }
}