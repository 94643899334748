.reportees-timesheet-update-button {
  width: 117px;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
  background: #3fc28a;
  color: #ffffff;
  border: none;
  font-family: "Lexend";
  font-size: 16px;
  font-weight: 500;
}
.reportees-timesheet-cancel-button {
  width: 117px;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  font-family: "Lexend";
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
}
.monthly-leave-update {
  font-size: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  border-radius: 10px;
  padding: 6px 20px;
  border: none;
  width: fit-content;
  text-wrap: nowrap;
}

/* button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  width: 60px;
  line-height: inherit;
} */
