.dashboard-main-div {
  border: 1px solid rgba(162, 161, 168, 0.2);
  border-radius: 10px;
  width: 98.5%;
  margin: 0 auto;
  margin-top: 2px;
}

.dashboard-content-div {
  width: 96%;
  margin: 15px auto;
  height: calc(100vh - 150px);
  overflow: auto;
  scrollbar-width: none;
}

/* For Laptop */
/* @media screen and (min-width: 769px) {
  .dashboard-main-div {
    height: calc(100vh - 81px);
  }
} */

/* For Tablet */
@media screen and (max-width: 768px) {
  .dashboard-main-div {
    height: calc(100vh - 66px);
  }
}

/* For Mobile */
@media screen and (max-width: 480px) {}