.search-icon-container {
    position: absolute;
    top: 33%;
    right: 30px;
    transform: translateY(-50%);
    cursor: pointer;

}

.ifscModal{
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
    max-width: 500px;
    min-width: 280px;
    border: none !important;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px solid #cccc !important;
    outline: none !important;
    z-index: 10000;
}
.search-ifsc-text{
    align-items: start;
    text-align: left;
    padding: 10px;
}
.ReactModal__Overlay {
    background-color: rgba(80, 79, 79, 0.678) !important;
}
.bank-row{
    border: 1px solid rgba(0, 0, 0, 1);
    padding: 10px;
}
.bank-head{
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    color: black;
}
.bank-value{
    font-size: 14px;
    font-weight: 300;
    text-wrap: wrap;
    padding: 4px;
    border: 1px solid rgba(0, 0, 0, 1);
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 768px) {
    .employee-list-container {
        margin-top: 0px !important;
    }
.add-bank-container{
    margin-top: 10px !important;
}
}

.add-bank-container{
    row-gap: 20px;
}