.reportees-attendance-list {
  height: max-content;
}

.reportees-attendance-list-container {
  width: 100%;
  margin: 0 auto;
  margin-top: 2px;
}

.reportees-attendance-list-banner {
  width: 100%;
  padding: 12px 20px 20px 20px;
  /* border-radius: 10px; */
  /* border: 1px solid #00000033;  */
  height: calc(100vh - 150px);
}

/* Top Section Filter and Button */
.reportees-Top-column {
  display: flex;
  gap: 10px;
}

.reportees-attendance-list-date-range {
  position: relative;
  z-index: 0;
}

.reportees-attendance-list-date-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
}

.reportees-attendance-list-date-range input {
  width: 282px;
  height: 40px;
  border-radius: 10px;
  padding: 13px 16px;
  border: 1px solid #00000033;
  cursor: pointer;
}

.reportees-attendance-list-date-range > input::placeholder {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #00000080;
}

.reportees-attendance-list-date-range input:focus {
  outline: none;
}

.reportees-attendance-list-status {
  position: relative;
}

.reportees-attendance-list-status .reportees-attendance-list-status-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.reportees-attendance-list-status input {
  width: 282px;
  height: 40px;
  border-radius: 10px;
  padding: 13px 16px;
  border: 1px solid #00000033;
}

.reportees-attendance-list-status-input::placeholder {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #00000080;
}

.reportees-attendance-list-status input:focus {
  outline: none;
}

.reportees-attendence-list-clock-btutton {
  width: 132px;
  height: 40px;
  border-radius: 10px;
  border: none;
  padding: 5px;
  background: #3fc28a;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.reportees-attendence-list-clock-in-button {
  width: 132px;
  height: 40px;
  border-radius: 10px;
  border: none;
  padding: 5px;
  background: #3fc28a;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.reportees-attendence-list-clock-out-button {
  width: 132px;
  height: 40px;
  border-radius: 10px;
  border: none;
  padding: 5px;
  background: #e25319e5;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

/* Table Section */
.reportees-attendance-list-profile {
  height: 36px;
  width: 36px;
  border-radius: 50px;
  color: black;
}

.reportees-attendance-list-table-container {
  margin-top: 10px;
  max-height: calc(100vh - 298px);
  overflow-y: auto;
  padding-left: 25px;
}

.reportees-attendance-list-table-container::-webkit-scrollbar {
  width: 2px;
}

.reportees-attendance-list-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.reportees-attendance-list-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.reportees-attendance-list-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.reportees-attendance-list-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

.reportees-attendance-list-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.reportees-attendance-list-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.reportees-attendance-list-table td {
  font-size: 16px;
  height: 47px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  vertical-align: middle;
}

.reportees-attendance-list-table th,
.reportees-attendance-list-table td {
  padding: 4px 10px 4px 0px;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
}

.reportees-attendance-list-table-status {
  height: 24px;
  width: 95px;
  padding: 3px, 8px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
}

/* pagenation */
.reportees-attendance-pagination {
  padding: 0;
  margin: 0;
  float: right;
}

.reportees-attendance-pagination li {
  display: inline-block;
  margin-right: 8px;
}

.reportees-attendance-pagination li button,
.reportees-attendance-pagination li span {
  border: none !important;
  background: none !important;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none !important;
}

.reportees-attendance-pagination-container {
  height: 56px;
}

.reportees-attendance-pagination > ul > li > button {
  padding: 0 !important;
  width: 31px !important;
  height: 32px !important;
  border-radius: 5px !important;
  background: none !important;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 5px 8px;
  font-weight: 300 !important;
  gap: 10px;
  border-radius: 8px;
}

.reportees-attendance-pagination > ul > li[aria-current="page"] > button {
  color: rgba(63, 194, 138, 1) !important;
  border: 1px solid rgba(63, 194, 138, 1) !important;
  text-align: center;
}

/* Attendance-record-list Showing */
.reportees-attendance-record-list > span {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #000000;
}

/* .reportees-attendance-record-list > select {
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  border: Mixed solid #00000033;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  cursor: pointer;
  
}

.reportees-attendance-record-list > select > option {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
} */

.reportees-attendance-record-list > div > select {
  height: 40px;
  width: 60px;
  padding: 10px;
  border-radius: 8px;
  border: Mixed solid #00000033;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  cursor: pointer;
}

.reportees-attendance-record-list > div > select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.reportees-attendance-record-list-dropdown-icon {
  position: absolute;
  right: 6px;
  top: 8px;
  pointer-events: none;
}




@media (max-width: 768px) {
  .reportees-Top-column {
    max-width: 100%;
    display: block;
  }
  .reportees-row {
    display: flex;
    flex-direction: column;
  }
  .reportees-attendance-list-date-range,
  .reportees-attendance-list-status,
  .reportees-attendence-list-clock-btutton {
    width: 100%;
    margin-bottom: 10px;
  }

  .reportees-attendance-list-date-range input {
    width: 100vw;
  }

  .reportees-attendance-list-status input {
    width: 100%;
  }

  .reportees-attendance-list-table td span {
    padding-left: 2px;
  }
  .reportees-attendance-list-table-container {
    margin-top: 10px !important;
    max-height: calc(100vh - 365px) !important;
    overflow-y: auto !important;
  }
}

@media (max-width: 1100px) {
  .reportees-Top-column {
    width: 100%;
    /* display: flex;
    flex-direction: column; */
  }

  .reportees-attendance-list-banner {
    width: 100%;
    padding: 10px 0px;
  }

  .reportees-attendence-list-clock-btutton {
    width: 100%;
  }

  .reportees-attendance-list-banner {
    height: calc(100vh - 105px);
  }

  .reportees-attendance-list-table-status {
    width: 75px;
  }

  .reportees-attendance-list-table-container {
    margin-top: 20px;
    max-height: calc(100vh - 280px);
    overflow-y: auto;
  }
}

/* Table */
@media screen and (max-width: 970px) {
  .reportees-attendance-list-table th:nth-child(6),
  .reportees-attendance-list-table td:nth-child(6),
  .reportees-attendance-list-table th:nth-child(7),
  .reportees-attendance-list-table td:nth-child(7) {
    display: none;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgba(63, 194, 138, 1);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgba(63, 194, 138, 1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.reportees-attendance-list-filter-input-container .form-control {
  width: 282px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #00000033;
  padding: 13px 16px 13px 16px;
  background-color: transparent;
}
.reportees-attendance-list-filter-icon {
  position: absolute;
  top: 40%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer !important;
  pointer-events: none;
}
.reportees-attendance-list-filter-input-container {
  width: 282px;
  position: relative;
}
.reportees-status-attendance-list-filter-input-container {
  width: 282px;
  position: relative;
}

.reportees-attendance-list-date-icon {
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
  z-index: 1;
}
.reportees-attendance-list-filter-input-container .reportees-custom-dropdown {
  width: 282px;
  height: 40px;
  border-radius: 10px;
}
.reportees-attendance-list-filter-input-container
  .reportees-custom-dropdown
  .p-icon.p-dropdown-clear-icon {
  top: 11px;
  right: 45px;
}

.reportees-attendance-list-filter-input-container
  .reportees-custom-dropdown
  .p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  padding: 2px 12px;
}

.reportees-status-attendance-list-filter-input-container
  .reportees-status-custom-dropdown {
  width: 282px;
  height: 40px;
  border-radius: 10px;
}
.reportees-status-attendance-list-filter-input-container
  .reportees-status-custom-dropdown
  .p-icon.p-dropdown-clear-icon {
  top: 11px;
  right: 45px;
}

.reportees-status-attendance-list-filter-input-container
  .reportees-status-custom-dropdown
  .p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  padding: 2px 12px;
}
@media (max-width: 1420px) {
  .reportees-status-attendance-list-filter-input-container {
    width: 220px;
    position: relative;
  }
  .reportees-status-attendance-list-filter-input-container
    .reportees-status-custom-dropdown {
    width: 220px;
    height: 40px;
    border-radius: 10px;
  }
  .reportees-status-attendance-list-filter-input-container
    .reportees-status-custom-dropdown
    .p-icon.p-dropdown-clear-icon {
    top: 11px;
    right: 45px;
  }

  .reportees-status-attendance-list-filter-input-container
    .reportees-status-custom-dropdown
    .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    padding: 2px 12px;
  }
}
@media (max-width: 1300px) {
  .reportees-status-attendance-list-filter-input-container {
    width: 180px;
    position: relative;
  }
  .reportees-status-attendance-list-filter-input-container
    .reportees-status-custom-dropdown {
    width: 180px;
    height: 40px;
    border-radius: 10px;
  }
  .reportees-status-attendance-list-filter-input-container
    .reportees-status-custom-dropdown
    .p-icon.p-dropdown-clear-icon {
    top: 11px;
    right: 45px;
  }

  .reportees-status-attendance-list-filter-input-container
    .reportees-status-custom-dropdown
    .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    padding: 2px 12px;
  }

  /* .reportees-hrs-bttn {
  margin-right: 20px;
} */
}
