.look-up-navbar {
  border-radius: 10px;
  /* opacity: 0px; */
  width: 97%;
  margin: 0 auto;
  margin-top: 2px;
  border: 1px solid #00000033;
  /* height: calc(100vh - 80px); */
}

.p-tabview-nav {
  height: 40px;
  gap: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  margin-top: 10px;
  margin-left: -30px;
  margin-right: 25px;
}

.p-tabview .p-tabview-nav {
  border-color: rgba(162, 161, 168, 0.2);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border: none !important;
  border-color: transparent !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: rgba(63, 194, 138, 1) !important;
  width: 57px;
  height: 3px;
  top: 0;
  margin-top: 37px;
  margin-left: -30px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: rgba(63, 194, 138, 1) !important;
  font-weight: 400 !important;
}

.look-up-project-tab {
  height: 61px;
  width: 550px;
  border-radius: 10px;
  background: rgba(36, 44, 68, 0.2);
  margin-top: 17px;
}

.look-up-project-tab > .p-float-label input {
  height: 40px;
  width: 400px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 20px;
  padding-left: 10px;
  border: none;
  color: black;
  background: rgba(255, 255, 255, 1);
}

.look-up-project-tab > .p-float-label > label {
  top: 0px;
  left: 0px;
  transform: translate(20px, 17px);
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.look-up-project-tab > .p-float-label input:focus ~ label,
.look-up-project-tab > .p-float-label input.p-filled ~ label {
  top: -40px;
  left: -16px;
  color: black;
  font-size: 16px;
  font-weight: 300;
}

.look-up-project-tab > div {
  margin-right: 20px;
  width: 73.84px !important;
  height: 40px !important;
  margin-top: 10px;
  text-align: center;
  padding: 1px;
}

.look-up-holiday-tab > .holiday-list-container {
  border: none !important;
  width: 100%;
  margin: 0 !important;
}

/* Leave Tab */
.look-up-project-leave-tab > .row > .col > p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.look-up-project-leave-tab > .row > .col > p > b {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.leave-list-tab-col {
  display: flex;
  gap: 10px;
  justify-self: center;
  align-items: center;
  /* min-width: 49%; */
  /* margin-top: 15px; */
}

.leave-list-tab-col > h6 {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  text-wrap: nowrap;
  text-align: center;
  margin: 0;
}

.leave-list-tab-col > .p-float-label {
  background-color: white;
  margin-left: 10px;
}

.level-list-tab-label {
  /* display: block; */
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  /* margin-left: 18px; */
  margin-top: -15px;
}

.leave-list-tab-col > .p-float-label > label {
  margin-left: 35px;
  margin-top: -10px;
}

.leave-list-tab-inputbox > .p-float-label input {
  background-color: white;
  color: black;
  width: 123px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 0 !important;
  padding: 10px !important;
}

.leave-list-tab-permission-row {
  margin-top: 35px;
  width: 81%;
}

/* .leave-list-tab-quarter {
  margin-left: 45px;
  gap: 10px;
  width: 50%;
} */

.level-list-tab-label-double {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  margin-top: -23px;
}

.leave-list-tab-inputbox-leave .p-float-label input:focus ~ label,
.leave-list-tab-inputbox-leave .p-float-label input.p-filled ~ label,
.leave-list-tab-inputbox-leave .p-float-label .p-inputwrapper-filled ~ label {
  display: flex !important;
  flex-direction: row;
  gap: 4px;
  color: black;
  top: -3px;
  left: 6px;
}

.leave-list-tab-inputbox-leave > .p-float-label input {
  background-color: white;
  color: black;
  width: 123px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 0 !important;
  padding: 10px !important;
}

/* ----------------------------------- */

/* Table Section */

.lookup-list-table-container {
  margin-top: 10px;
  margin-left: 10px;
  width: 50%;
  max-height: calc(100vh - 255px);
  overflow-y: auto;
}

.lookup-list-table-container::-webkit-scrollbar {
  width: 2px;
}

.lookup-list-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.lookup-list-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.lookup-list-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lookup-list-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

.lookup-list-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.lookup-list-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.lookup-list-table td {
  font-size: 16px;
  height: 47px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  vertical-align: middle;
}

.lookup-list-table th,
.lookup-list-table td {
  padding: 4px 0px 4px 0px;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
}

/* Table Section 2 */

.lookup-list-holiday-table-container {
  margin: 10px;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}

.lookup-list-holiday-table-container::-webkit-scrollbar {
  width: 2px;
}

.lookup-list-holiday-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.lookup-list-holiday-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.lookup-list-holiday-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lookup-list-holiday-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

.lookup-list-holiday-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.lookup-list-holiday-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.lookup-list-holiday-table td {
  font-size: 16px;
  height: 47px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  vertical-align: middle;
}

.lookup-list-holiday-table th,
.lookup-list-holiday-table td {
  padding: 4px 10px 4px 0px;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
}

.look-up-leaves-add-btn {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  border-radius: 8px;
  padding: 8px 30px;
  border: none;
  height: 40px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-leave-error-message {
  position: absolute;
  font-size: 13px;
  color: red;
  left: 7px;
}

/* Media Query */

@media screen and (max-width: 1024px) {
  .look-up-project-tab-table {
    width: 100%;
  }

  .leave-list-tab-quarter,
  .leave-list-tab-permission-row {
    width: 100% !important;
    margin-left: 0px !important;
    display: flex;
    flex-wrap: wrap;
  }

  .leave-list-tab-permission-col {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    align-items: center;
    justify-content: end;
  }

  .lookup-list-table-container {
    width: 72%;
  }
}

@media screen and (max-width: 1024px) {
  .look-up-project-tab-table {
    width: 100%;
  }

  .leave-list-tab-quarter,
  .leave-list-tab-permission-row {
    width: 100% !important;
    margin-left: 0px !important;
    display: flex;
    flex-wrap: wrap;
  }

  .leave-list-tab-permission-col {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    align-items: center;
    justify-content: end;
  }
}

@media screen and (max-width: 995px) {
  .look-up-project-tab {
    width: fit-content;
    height: fit-content;
    padding-bottom: 10px;
  }

  .lookup-list-table-container {
    width: 73%;
  }

  .look-up-project-tab > .p-float-label input {
    max-width: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .look-up-project-tab > div {
    width: 100%;
    margin-left: 20px;
  }

  .lookup-list-table-container {
    width: 70%;
  }
}

@media screen and (max-width: 375px) {
  .p-tabview-nav {
    height: 100px;
  }
}

.leave-list-tab-inputbox .p-float-label input:focus ~ label,
.leave-list-tab-inputbox .p-float-label input.p-filled ~ label,
.leave-list-tab-inputbox .p-float-label .p-inputwrapper-filled ~ label {
  display: flex !important;
  flex-direction: row;
  gap: 4px;
  color: black;
  top: -10px;
  left: 6px;
}
.lat-long-input {
  background-color: white;
  color: black;
  width: 200px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
}
.presence-btn-update {
  font-size: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  border-radius: 10px;
  padding: 6px 20px;
  border: none;
  width: fit-content;
  text-wrap: nowrap;
}

.checkbox-presence {
  accent-color: #3fc28a !important;
}
