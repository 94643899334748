.employee-list-container-personal {
  margin-top: 0px;
}

.employee-list-banner {
  width: 97%;
  margin: 0 auto;
}

.employee-list-table-holder {
  width: 100%;
  padding: 12px 20px 15px 20px;
  border-radius: 10px;
  border: 1px solid #00000033;
}

.Employee-photo-container {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.addEmployee-details {
  padding-top: 10px;
}

::-moz-placeholder {
  color: black;
}

:-ms-add-employee-input-placeholder {
  color: black;
}

::placeholder {
  color: black;
}

.add-Employee-form-item {
  position: relative;
}

.add-Employee-label {
  display: block;
  font-size: 16px;
  margin-top: 3px;
  pointer-events: none;
}

.add-Employee-input {
  width: 100%;
  font-size: 16px;
  height: 48px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  text-wrap: wrap;
  object-fit: cover;
  outline: none;
  transition: all 0.2s ease-in-out;
  color: black;
  border: none;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  margin-top: 0;
  z-index: 1;
}

.add-Employee-form-item--text .add-Employee-label {
  position: absolute;
  top: 0;
  /* z-index: -1; */
  left: 0;
  color: black;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  transform: translate(15px, 8px);
}

.add-Employee-form-item--text .add-Employee-input:focus ~ .add-Employee-label,
.add-Employee-form-item--text
  .add-Employee-input:not(:placeholder-shown)
  ~ .add-Employee-label {
  color: black;
  font-weight: 300;
  font-size: 16px;
  transform: translate(0, -26px);
}

/* error message */
.error-message-text {
  color: red;
  font-size: 13px;
  position: absolute;
  left: 5px;
  top: 50px;
}

.select-container {
  position: relative;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.05);
  text-wrap: wrap;
  object-fit: cover;
  outline: none;
  transition: all 0.2s ease-in-out;
  color: black;
  border: none;
  border-radius: 10px;
  margin-top: 100px;
  height: 50px;
}

.floating-label {
  position: absolute;
  z-index: -1;
  margin: 0 auto;
  font-size: 16px;
  color: black;
  transition: all 0.2s ease-in-out;
  transform: translate(12px, 10px);
}

.active .floating-label {
  position: absolute;
  margin-top: -35px;
  font-size: 16px;
  color: black;
  margin-left: -10px;
  font-weight: 300;
}

.css-qmhy07-control {
  border: 1px solid black !important;
}

.select-container:focus {
  box-shadow: none !important;
  outline: none;
  border: 1px solid black;
  background-color: white !important;
  height: 55px;
}

.css-12vymmw-control {
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-12vymmw-control:focus {
  background-color: white !important;
}

.css-1h46b4p-control {
  box-shadow: none !important;
  border-color: black !important;
  border-radius: 10px !important;
}

.css-1h46b4p-control:hover {
  box-shadow: none !important;
  border-color: black !important;
}

.css-1h46b4p-control:focus {
  box-shadow: none !important;
  border-color: black !important;
  background-color: white !important;
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  box-shadow: none !important;
  border: none !important;
}

.css-1nmdiq5-menu {
  border-radius: 10px !important;
  margin-top: 0;
  padding-top: 0;
  position: relative;
  padding: 0% !important;
}

.css-8bcpae-control {
  border: none !important;
  margin-bottom: 20px !important;
}

.css-qmhy07-control {
  height: 48px !important;
}

.add-Employee-input:focus {
  box-shadow: none;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 48px;
}

input[type="date"]::placeholder {
  visibility: hidden;
}

input:-internal-autofill-selected,
textarea:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -moz-box-shadow: 0 0 0 0 white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.Cancel-btn {
  border: 1px solid #000000;
  gap: 10px;
  border-radius: 10px;
  padding: 8px;
  margin-right: 10px;
  height: 45px;
  width: 90px;
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.add-button-next {
  width: 90px;
  height: 45px;
  border-radius: 10px;
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  padding: 8px;
  border: none;
}

.add-button-prev {
  width: 90px;
  height: 45px;
  border-radius: 10px;
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  padding: 8px;
  border: none;
  margin-right: 10px;
}

.ReactModal__Overlay {
  z-index: 2 !important;
}

@media screen and (max-width: 768px) {
  .Employee-profile-container {
    margin-top: 20px;
  }

  .employee-list-container-personal {
    margin-top: -20px !important;
  }

  .add-emp-row-1 {
    margin-top: 20px;
  }

  .add-box-personal {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 968px) {
  .add-box-personal {
    margin-bottom: 20px;
  }
}

.add-emp-row-1 {
  padding-top: 10px;
  row-gap: 20px;
}

.add-Employeepage-buttons {
  display: flex;
  justify-content: end;
}

.floating-label-datepicker {
  margin-bottom: 0px !important;
}

.floating-label-datepicker > label {
  padding: 10px;
}

.add-employee-form {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.auth-select__control .css-8bcpae-control {
  margin-bottom: 10px;
}

.Employees-Label {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s ease;
  font-size: 16px;
}

.add-employee-form.focused .Employees-Label {
  top: -15px;
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 10px;
  transform: translate(0, -18px);
}

/* Calendar */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s !important;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.filled-bg input {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.p-float-label-personal input {
  padding-right: 140px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: #0000000d;
  color: black;
  padding-left: 10px;
  margin-left: none !important;
  height: 48px !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-datepicker:not(.p-datepicker-inline) {
  z-index: 9999999 !important;
}

.floating-label-datepicker .p-inputtext:focus {
  outline: none;
  border: 1px solid black !important;
}

.date-picker-label {
  position: absolute;
  left: 0;
  margin-left: 10px;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-top: 12px;
  pointer-events: none;
}

.p-float-label-personal input:focus ~ label,
.p-float-label-personal .p-inputwrapper-filled ~ label {
  margin-top: -22px;
  margin-left: 2px !important;
  font-weight: 300;
}

/* .css-1xc3v61-indicatorContainer {
  margin-top: 5px !important;
} */

.custom-calander.p-component {
  z-index: -1 !important;
}
.custom-calendar.filled-bg input:disabled {
  font-family: Lexend;
  font-size: 16px;
  height: 48px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 1) !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.atnd_gender__control,
.atnd_nationality__control,
.atnd_state__control,
.atnd_city__control,
.atnd_active__control,
.atnd_supervisor__control {
  border: none !important;
}

.atnd_gender__indicator-separator,
.atnd_nationality__indicator-separator,
.atnd_state__indicator-separator,
.atnd_city__indicator-separator,
.atnd_active__indicator-separator,
.atnd_supervisor__indicator-separator {
  background-color: transparent !important;
}
