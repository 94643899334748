.reportees-timesheet-list {
  height: max-content;
}

.reportees-timesheet-list-container {
  width: 100%;
  margin: 0 auto;
  margin-top: 2px;
}

.reportees-timesheet-list-banner {
  width: 100%;
  padding: 12px 20px 20px 20px;
  /* border-radius: 10px;
    border: 1px solid #00000033; */
  /* height: calc(100vh - 80px); */
}

.reportees-timesheet-top-column {
  display: flex;
  gap: 10px;
}

.reportees-timesheet-list-filter {
  width: 282px;
  height: 40px;
  position: relative;
}

.reportees-timesheet-list-filter .form-control {
  width: 282px;
  height: 40px;
  padding: 13px 16px;
  border: 1px solid #00000033;
  border-radius: 10px;
}

.reportees-timesheet-list-filter .form-control::placeholder {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #00000080;
}

.reportees-timesheet-list-filter-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.reportees-timesheet-list-date-range input {
  width: 282px;
  height: 40px;
  border-radius: 10px;
  padding: 13px 16px;
  border: 1px solid #00000033;
  cursor: pointer;
}

.reportees-timesheet-list-date-range>input::placeholder {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #00000080;
}

.reportees-timesheet-list-date-range {
  position: relative;
}

.reportees-timesheet-list-date-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
  z-index: 9999;
}

.reportees-timesheet-export-button {
  width: 117px;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
  background: #3fc28a;
  color: #ffffff;
  border: none;
  font-family: "Lexend";
  font-size: 16px;
  font-weight: 500;
}


.reportees-timesheet-export-button-icon {
  margin-left: 10px;
  position: relative;
  bottom: 3px;
}

/* TimeSheet Data Add list Section */

.reportees-timesheet-list-top-container {
  background-color: #242c4433;
  height: 60px;
  width: 100%;
  margin-top: 10px;
}

.reportees-timesheet-list-top-column-2 {
  display: flex;
  padding: 10px 10px;
  border: none;
  gap: 1px;
}

.reportees-timesheet-list-project-add {
  position: relative;
}

.reportees-timesheet-list-project-add input {
  width: 100%;
  height: 40px;
  border-radius: 10px 0 0 10px;
}

.reportees-timesheet-list-project-add-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.reportees-timesheet-list-task-add {
  position: relative;
}

.reportees-timesheet-list-task-add input {
  width: 100%;
  height: 40px;
  border-radius: 0;
}

.reportees-timesheet-list-task-add-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.element.style {
  width: 100% !important;
  height: 40px !important;
  background: white !important;
}

.css-10o2lyd-MuiStack-root>.MuiTextField-root,
.css-10o2lyd-MuiStack-root>.MuiPickersTextField-root {
  min-width: 0px !important;
}

element.style {
  height: 100% !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0px !important;
  padding-right: 0px !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
  display: flex !important;
  background-color: white !important;
  min-height: 38px !important;
  margin-top: -7px !important;
  border: none !important;
  width: 100% !important;
  padding-left: 8px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.reportees-timesheet-list-duration>input {
  font-family: Lexend;
  width: 80%;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  border-radius: 0 10px 10px 0 !important;
}

.reportees-timesheet-list-duration>input::placeholder {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #00000080;
}

.reportees-timesheet-list-add-button {
  width: 70px;
  height: 40px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: #3fc28a;
  color: #ffffff;
}

/* Table Section */

/* .reportees-timesheet-list-table-container { */
/* margin-top: 20px; */
/* max-height: calc(100vh - 315px);
  overflow-y: auto; */
/* padding-top: 10px; */
/* padding-left: 25px; */
/* } */

.reportees-timesheet-list-table-container::-webkit-scrollbar {
  width: 2px;
}

.reportees-timesheet-list-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.reportees-timesheet-list-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.reportees-timesheet-list-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.reportees-timesheet-list-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

.reportees-timesheet-list-table {
  width: 100%;
  border-collapse: collapse;
}

.reportees-timesheet-list-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.reportees-timesheet-list-table td {
  font-size: 15px;
  height: 47px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;

}

.reportees-timesheet-list-table th,
.reportees-timesheet-list-table td {
  padding: 4px 10px 4px 0px;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
}

/* Record showing */

.reportees-timesheet-record-list>span {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #000000;
}

/* .reportees-timesheet-record-list>select {
    height: 40px;
    padding: 10px;
    border-radius: 10px;
    border: Mixed solid #00000033;
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    background: transparent;
    cursor: pointer;
}

.reportees-timesheet-record-list>select>option {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
} */

.reportees-timesheet-record-list>div>select {
  height: 40px;
  width: 60px;
  padding: 10px;
  border-radius: 8px;
  border: Mixed solid #00000033;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  cursor: pointer;
}

.reportees-timesheet-record-list>div>select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}



.reportees-timesheet-pagination {
  padding: 0;
  margin: 0;
  float: right;
}

.reportees-timesheet-pagination li {
  display: inline-block;
  margin-right: 8px;
}

.reportees-timesheet-pagination li button,
.reportees-timesheet-pagination li span {
  border: none !important;
  background: none !important;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none !important;
}

.reportees-timesheet-pagination-container {
  height: 56px;
}

.reportees-timesheet-pagination>ul>li>button {
  padding: 0 !important;
  width: 31px !important;
  height: 32px !important;
  border-radius: 5px !important;
  background: none !important;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 5px 8px;
  font-weight: 300 !important;
  gap: 10px;
  border-radius: 8px;
}

.reportees-timesheet-pagination>ul>li[aria-current="page"]>button {
  color: rgba(63, 194, 138, 1) !important;
  border: 1px solid rgba(63, 194, 138, 1) !important;
  text-align: center;
}

.reportees-timesheet-list-table-container {
  overflow-y: auto;
  height: calc(100Vh - 300px);
}

.reportees-TimesheetScrollContainer {

  margin-top: 10px;
}

/* Media query*/

@media only screen and (max-width: 1320px) {
  .reportees-timesheet-list-banner {
    height: calc(100vh - 85px);
  }

  .reportees-timesheet-list-table-container {
    margin-top: 10px;
    max-height: calc(100vh - 295px);
    /* overflow-y: auto; */
  }

  .reportees-timesheet-list-table-container th {
    top: -11px;
  }
}

@media only screen and (max-width: 1024px) {
  .reportees-TimesheetScrollContainer {
    overflow-x: scroll;
    position: relative;

  }

  .reportees-timesheet-list-top-container {
    width: fit-content;
    width: max-content;
  }

  .reportees-timesheetTableScroll {
    width: max-content;
  }

  .reportees-timesheet-list-banner {
    height: calc(100vh - 95px);
  }

  .reportees-timesheet-list-table-container {
    margin-top: 10px;
    max-height: calc(100vh - 310px);
    overflow-y: auto;
  }

  .reportees-timesheet-list-table-container th {
    top: -11px;
  }
}

@media (max-width: 768px) {
  .reportees-timesheet-top-column {
    max-width: 100%;
    display: block;
    margin: auto;
  }

  .reportees-timesheet-list-filter,
  .reportees-timesheet-list-date-range,
  .reportees-timesheet-export-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .reportees-timesheet-list-filter input {
    width: 100vw;
  }

  .reportees-timesheet-list-date-range input {
    width: 100vw;
  }

  .reportees-timesheet-list-table {
    width: 100%;
    border-collapse: collapse;
  }

  .reportees-timesheet-list-table-container {
    margin-left: 0px;
  }

  .reportees-timesheet-list-banner {
    height: calc(100vh - 110px);
  }

  .reportees-timesheet-list-table-container {
    margin-top: 10px;
    max-height: calc(100vh - 440px);
    overflow-y: auto;
  }

  .reportees-timesheet-list-table-container th {
    top: -13px;
  }
}

.reportees-timesheet-record-list-dropdown-icon {
  position: absolute;
  right: 6px;
  top: 8px;
  pointer-events: none;
}