.Login-banner {
  background-color: rgba(57, 199, 137, 0.06);
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.image-row {
  height: 100%;
}
.logincard-con {
  width: 65%;
  margin-top: 50px;
  margin-left: 180px;
}
.Login-card {
  background-color: rgba(255, 255, 255, 0.89);
  padding: 20px;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
.login-card-col {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.Midfin-logo {
  margin: 15px 0px ;
}
.Midfin-png {
  width: 40px;
  height: 50px;
  margin: 0 auto;
}
.Midfin-png-text {
  margin: 0 auto;
}
.welcome-text {
  font-family: "Lexend", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  color: rgba(0, 0, 0, 1);
  text-wrap: nowrap;
  margin-bottom: 0;
}
.loginhere-text {
  font-family: "Lexend", sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
  margin-top: 0;
}
.login-inputs {
  margin-top: 20px;
}
.Login-Label {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(0, 0, 0, 1);
}
.login-inputBox {
  width: 100%;
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  border: 1px solid #6666;
  border-radius: 12px;
  padding: 10px;
}
.login-inputBox:focus {
  border: 1px solid black;
  outline: none;
}
.loginform-container {
  margin-top: 40px;
}
.forget-password-text {
  font-family: "Lexend", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-decoration: underline;
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
}
.PasswordHide-text {
  font-family: "Lexend", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  cursor: pointer;
}

.Login-button {
  color: white;
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: rgba(36, 44, 68, 1);
  gap: 10;
  padding: 10px;
  margin-top: 50px;
  border: none;
}

.loginimage-background {
  padding-bottom: 100px;
  margin-left: 100px;
  background-color: #c0dbea;
  position: relative;
  border-radius: 40px;
}
.WorkingLaptopImage {
  background: transparent;
  background-image: none;
  position: absolute;
}
.work-with-laptop-image {
  top: 100px;
  left: -120px;
  width: auto;
}
.Cactus-image {
  margin-left: 390px;
  margin-top: -30px;
  height: 400px;
  width: auto;
}

@media screen and (max-width: 1100px) {
  .logincard-con {
    width: 65%;
    margin-top: 40px;
  }
}

@media screen and (max-width: 992px) {
  .logincard-con {
    width: 65%;
    margin-left: 100px;
  }
  .work-with-laptop-image {
    width: 80%;
  }
  .loginimage-background {
    padding-bottom: 100px;
    margin-left: 100px;
  }
}
@media screen and (max-width: 768px) {
  .login-image-col {
    display: block;
  }
  .logincard-con {
    float: none;
    width: 65%;
    padding: 10px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .Cactus-image {
    margin-left: 390px;
    margin-top: -30px;
    height: 300px;
  }
}
@media screen and (max-width: 1000px) {
  .loginimage-background {
    padding-bottom: 100px;
    margin-left: 50px;
  }
  .work-with-laptop-image {
    top: 100px;
    left: -170px;
    width: auto;
  }
  .Cactus-image {
    margin-left: 390px;
    margin-top: -30px;
    height: 400px;
    width: auto;
  }
  .Login-button {
    margin-top: 40px;
  }
}
@media screen and (max-width: 850px) {
  .logincard-con {
    float: none;
    width: 80%;
    padding: 10px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .loginimage-background {
    padding-bottom: 100px;
  }
  .work-with-laptop-image {
    left: -180px;
  }
  .Cactus-image {
    margin-left: 390px;
    margin-top: -30px;
    height: 400px;
    width: auto;
  }
  .Login-button {
    margin-top: 30px;
  }
}

@media screen and (max-width: 576px) {
  .logincard-con {
    float: none;
    width: 100%;
    padding: 10px;
  }
}
@media screen and (max-width: 375px) {
  .login-card-col {
    margin: 0 auto;
    padding: 0%;
  }
  .logincard-con {
    padding: 0px;
  }
}
