.holiday-list-container-main {
  height: max-content;
  border: 1px solid rgba(162, 161, 168, 0.2);
  border-radius: 10px;
  width: 97%;
  margin: 0 auto;
  padding-top: 10px;
  margin-top: 0px;
  height: calc(100vh - 80px);
}

.holiday-list-text-holder {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.holiday-list-text-holder > h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  text-wrap: nowrap;
}

.holiday-list-filters-input-container {
  position: relative;
}

.holiday-year-filter > .p-component .p-inputtext {
  width: 132px;
  height: 40px;
  position: relative;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 20px;
  margin-top: -5px;
  border: 1px solid #00000033;
  border-radius: 10px;
  cursor: pointer;
}

.holiday-filter-icon {
  position: absolute;
  top: 40%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
  z-index: 9999;
}

/* Table Section */

.holiday-table-container {
  margin: 10px;
  max-height: calc(100vh - 205px);
  overflow-y: auto;
}

.holiday-table-container::-webkit-scrollbar {
  width: 2px;
}

.holiday-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.holiday-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.holiday-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.holiday-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

.holidays-list-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.holidays-list-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.holidays-list-table td {
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
}

.holidays-list-table th,
.holidays-list-table td {
  height: 40px !important;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
}

.holidays-hr-line {
  width: 30px;
  font-size: 20px;
  opacity: 1;
  margin-left: -10px;
  transform: rotate(90deg);
}

.past-holiday {
  border: 2px solid rgba(0, 0, 0, 1);
}

.upcoming-holiday {
  border: 2px solid rgba(63, 194, 138, 1);
}

.circle-green {
  width: 10px;
  height: 10px;
  border: 1px solid rgba(63, 194, 138, 1);
  border-radius: 50px;
  background-color: rgba(63, 194, 138, 1);
}

.circle-black {
  width: 10px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, 1);
  border-radius: 50px;
}

.holidays-indicator > p {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-top: 1px;
  text-wrap: nowrap;
}

/* Modal */

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.holiday-confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* animation: slideIn 0.5s linear forwards; */
}

/* .holiday-confirm-modal[data-out="true"] {
  animation: slideOut 0.5s linear forwards;
} */

.holiday-confirm-modal-container {
  height: fit-content;
  width: 30%;
  gap: 0px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.holiday-confirm-modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0px;
  padding: 20px;
  overflow-y: hidden;
}

.holiday-confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* animation: slideIn 0.5s ease-in-out; */
}

.holiday-confirm-modal-container > div {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.modal-cancel-button,
.modal-add-button {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid black;
  font-size: 20px;
  font-weight: 400;
  font-family: "Lexend", sans-serif;
  background: none;
}

.modal-add-button {
  background: #3fc28a !important;
  border: none !important;
}

.holiday-confirm-modal-container > h1 {
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.action > button {
  border: none;
  background-color: none;
  background: transparent;
}

.readonly-label {
  position: relative;
  top: -38px !important;
  transition: none !important;
  transform: none !important;
}

/* Floating Lable */

.floating-label-datepicker-lookup .p-float-label input {
  height: 55px;
  width: 100%;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: #0000000d;
  color: black;
  padding-left: 10px;
  margin-left: none !important;
}

.floating-label-datepicker-lookup .p-float-label input:focus ~ label,
.floating-label-datepicker-lookup .p-float-label input.p-filled ~ label,
.floating-label-datepicker-lookup
  .p-float-label
  .p-inputwrapper-filled
  ~ label {
  top: -0.75rem;
  left: -6px;
  background: rgba(255, 255, 255, 1);
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: none;
}

.floating-label-datepicker-lookup
  .p-float-label
  .holiday-date-picker-label
  input:focus {
  top: -0.75rem !important;
  left: -6px !important;
  background: rgba(255, 255, 255, 1);
}

.p-button {
  color: black;
  background: none;
  position: absolute;
  left: 87%;
  top: 25%;
  border: none;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.floating-label-datepicker-lookup {
  margin-bottom: 45px;
}

.holiday-date-picker-label {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-top: -15px;
  padding-left: 10px;
  color: black;
}

thead {
  text-align: center;
}

.p-datepicker-header {
  height: 50px;
}

.p-datepicker-year {
  margin-left: 10px;
}

input:-internal-autofill-selected,
textarea:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -moz-box-shadow: 0 0 0 0 white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.p-error {
  color: red;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  margin-left: 10px;
}

.p-error1 {
  color: red;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  margin-left: 10px;
}

/* DELETE MODAL */
.delete-confirm-modal-title {
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;
  width: fit-content;
  margin: 20px auto;
  color: rgba(0, 0, 0, 1);
}

.delete-confirm-modal-data {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.delete-confirm-modal-container {
  width: 30%;
  gap: 0px;
  padding: 30px;
  border-radius: 20px;
  /* opacity: 0px; */
}

.modal-confirm-button-delete {
  width: 150px;
  height: 50px;
  font-family: "Lexend", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  background-color: rgba(244, 91, 105, 0.8);
  border-radius: 10px;
  padding: 6px;
  border: none;
}

.floating-label-datepicker-lookup .p-inputtext:focus,
.floating-label-datepicker-lookup
  .p-float-label
  .custom-calendar.filled-bg
  input,
.floating-label-datepicker-lookup .p-float-label .p-filled {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.floating-label-datepicker-lookup
  .p-float-label
  .custom-calendar.filled-bg
  input:disabled {
  font-family: Lexend;
  font-size: 16px;
  height: 55px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 1) !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 1;
}

/* Media Query */
@media screen and (max-width: 1280px) {
  .holiday-confirm-modal-container {
    height: fit-content;
    width: 35%;
    gap: 0px;
    border-radius: 20px;
    /* opacity: 0px; */
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 930px) {
  .delete-confirm-modal-container {
    width: 35%;
    gap: 0px;
    padding: 30px;
    border-radius: 20px;
    /* opacity: 0px; */
  }
  .holiday-confirm-modal-container {
    height: fit-content;
    width: 45%;
    gap: 0px;
    border-radius: 20px;
    /* opacity: 0px; */
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .holiday-list-filters-input-container {
    margin-top: -10px;
  }

  .holiday-list-row {
    gap: 10px;
  }
  .employee-list-add-employee-button-holiday
    .employee-list-filter-input-container
    > input {
    margin-top: 0px;
    width: 100%;
    /* margin: 10px 0px 0px 0px; */
  }

  .look-up-holiday-tab {
    margin-top: 50px t;
  }

  .delete-confirm-modal-container {
    width: 45%;
    gap: 0px;
    padding: 30px;
    border-radius: 20px;
    /* opacity: 0px; */
  }
  .holiday-confirm-modal-container {
    height: fit-content;
    width: 50%;
    gap: 0px;
    border-radius: 20px;
    /* opacity: 0px; */
    background: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 430px) {
  .holiday-confirm-modal-container {
    width: 100%;
  }

  .modal-add-button,
  .modal-confirm-button,
  .modal-cancel-button {
    width: 100%;
    margin: 0 auto;
  }

  .modal-cancel-button {
    width: 100%;
    margin-left: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .holiday-list-text-holder > h1 {
    text-wrap: wrap;
  }

  .floating-label-datepicker-lookup .p-float-label input {
    /* padding-right: 10px; */
    width: 100%;
  }

  .holidays-list-table {
    margin: 0 auto;
  }
}

@media screen and (max-width: 320px) {
  .holidays-list-table {
    margin-left: -10px;
  }
}
