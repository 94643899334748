.sidebar-container {
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  border-radius: 10px;}
.sidebar-container.collapsed {
  width: 100px;
  transition: all 1s ease-in;
}

.sidebar-container.expanded {
  width: 235px;
  transition: all 1s ease-in;
}
.sidebar-logo-container {
  height: 68px;
  width: 235px;
  display: flex;
  justify-content: center;
  border: 1.5px solid #000000;
  border-radius: 10px;
  transition: all 1s ease-in;
  margin: 5px 0px 5px 0px;
}
.sidebar-logo {
  height: 45px;
  transition: all 1s ease-in;
  margin: 10px 0px;
}
.sidebar-list-inner-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 9px 5px;
  text-decoration: none;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  margin: 6px 10px;
}
.sidebar-list-inner-container.active {
  border-left: 3px solid #3fc28a;
  border-radius: 0px 10px 10px 0px;
  margin: 6px 10px;
}

.sidebar-list-container {
  display: flex;
  flex-direction: column;
  width: 235px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  border-radius:10px;
  left: 2px;
  padding: 13px;
}
.sidebar-list-container-collapsed,
.sidebar-logo-container-collapsed {
  width: 100px;
}

.sidebar-list-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.sidebar-list-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #f69f3a;
  background-color: rgba(246, 159, 58, 0.29);
  transition: all 1s ease-in;
}
.sidebar-list-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  transition: all 1s ease-in;
  text-wrap: nowrap;
}
.sidebar-list-inner-container.active .sidebar-list-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.sidebar-list-collapse-container {
  display: flex;
  justify-content: center;
  padding: 10px;

}
.sidebar-collapse-icon {
    transition: all 1s ease-in;
}

.sidebar-list-inner-container:hover {
  background-color: rgba(255, 255, 255, 0.2);
  align-items: center;
  justify-content: flex-start !important;
  border-left: 3px solid #3fc28a;
  border-radius: 0px 10px 10px 0px;
  margin: 6px 10px;
}
.sidebar-list-inner-container:hover > .hover-hide-logo {
  display: none;
}
.hover-show-logo {
  display: none;
  width: 30px;
}
.hover-hide-logo {
  width: 30px;
}
.sidebar-list-inner-container:hover > .hover-show-logo {
  display: block;
}
.sidebar-list-inner-container-collapsed {
  display: flex;
  flex-direction: column;
}
.sidebar-list-inner-container:hover .sidebar-list-text {
  color: #3fc28a;
  /* transition: all 0.5s ease-in; */
  transition: all 1s ease-in;
}
.sidebar-list-inner-container.active .hover-hide-logo {
  display: none;
}
.sidebar-list-inner-container.active .hover-show-logo {
  display: block;
}

.sidebar-list-inner-container.active .collapsed-sidebar-list-text {
  text-wrap: wrap;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0px;
  text-align: center;
} 
.collapsed-sidebar-list-text {
  text-wrap: wrap;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 10px;
} 
.sidebar-list-inner-container-collapsed-icon { 
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}

/* ------------------ */

.nav-item-list{
  overflow-y: auto;
  height: 100vh;
}

.nav-item-list::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width: 768px){
  .sidebar-container{
    display: none !important;
  }
  
}
