.header-container {
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  z-index: 2;

}


.employee-list-header-text {
  margin-left: 0px;
}

.employee-list-header-text>p {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-wrap: nowrap;
  margin-top: -18px;
}

.header-title-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  margin-left: 10px;
  width: auto;
  word-wrap: normal;
}

.header-title-text-wrap {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000000;
  text-wrap: nowrap;
}

.header-title-text-wrap-mob {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  text-wrap: nowrap;
}

.nav-link {
  color: #000000;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  height: 50px;
  display: flex;
  align-items: center;
}

.navbar-nav {
  background-color: rgba(36, 44, 68, 1);
  height: 100%;
}


.toggle-icon {
  color: #ffffff;
  font-size: 30px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.nav-link:hover {
  border-bottom: 3px solid #3fc28a;
  transition: all 0.5 ease-in;
}

.nav-link:hover {
  transition: all 0.5 ease-in;
  color: #3fc28a !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
}

.hr-line {
  color: white;
  width: 30px;
  font-size: 20px;
  opacity: 1;
  transform: rotate(90deg);
}

.header-dropdown-icon {
  color: black;
  font-size: 20px;
}

.header-sidebar-main {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  z-index: 9999;
  top: 0;
  background-color: rgba(36, 44, 68, 1);
  padding: 5px;
  transition: all 0.5s ease-in;
}

.nav-link.active {
  color: #3fc28a !important;
  transition: all 0.5 ease-in;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  border-bottom: 3px solid #3fc28a;
}

.profile-container {
  background-color: transparent !important;
  cursor: pointer;
}

.profile-container>.dropdown-toggle::after {
  display: none !important;
}

.logo-show-head {
  display: none;
}

.sidebar-list-inner-container.active>.logo-hide-head {
  display: none;
}

.sidebar-list-inner-container.active>.logo-show-head {
  display: block;
}

.header-title-text-con {
  width: min-content !important;
  margin-right: 10px !important;
}

.header-nav-profile-image {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: #3fc28a;
}

.employee-list-select-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-list-profile-holder {
  display: flex;
  align-items: center;
}

.employee-list-profile-header {
  width: 40px !important;
  height: 42px !important;
  border-radius: 8px !important;
}

.header-dropdown-icon {
  color: black;
  font-size: 20px;
}

.employee-list-header-dropdown-icon {
  margin-top: -10px;
}

.full-page-details {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  z-index: 9999;
  top: 0;
  background-color: rgba(36, 44, 68, 1);
  padding: 20px;
  color: white;
  transition: all 0.5s ease-in;
}

.cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* .header-page-title {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  font-family: Lexend;
  text-align: left;
} */

.breadcrumb-separator {
  content: "›";
  margin: 0 5px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .employee-list-header-text>p {
    display: none;
  }

  .employee-list-header-text {
    margin-left: -5px;
  }
}