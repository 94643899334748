.leave-list-container {
  padding-top: 5px;
}

.leave-list-banner {
  width: 98%;
  margin: 0 auto;
}

.leave-list-header-text {
  margin-left: -13px;
}

.leave-list-header-text>h6 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000000;
}

.leave-list-header-text>p {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: -7px;
}

.leave-list-profile {
  height: 40.2px;
  width: 40px;
  border-radius: 50px;
}

.leave-list-select-holder {
  width: 200px;
  height: fit-content;
}

.leave-list-select-holder:hover {
  cursor: pointer;
}

.leave-list-profile-holder>p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.leave-list-dropdown-content {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 8px;
  width: 200px;
  padding: 5px 50px 5px 10px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  display: block;
  z-index: 1;
}

.leave-list-dropdown-content>a,
.leave-list-dropdown-content>div>a {
  text-decoration: none;
}

.leave-list-profile1 {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-wrap: nowrap;
  color: rgba(0, 0, 0, 1);
}

.leave-list-logout {
  color: rgba(226, 83, 25, 1);
  text-wrap: nowrap;
}

.leave-list-filter-input-container>input {
  width: 330px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #00000033;
  padding: 13px 16px 13px 16px;
  background-color: transparent;
}

.leave-list-filter-input-container>input::placeholder {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.leave-list-filter-input-container {
  position: relative;
  margin-left: -10px;
}

.leave-list-filter-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.leave-list-filter-input-container input:focus {
  outline: none;
}

.leave-list-table-holder {
  width: 100%;
  padding: 12px 20px 15px 20px;
  border-radius: 10px;
  border: 1px solid rgba(162, 161, 168, 0.2);
  height: calc(100vh - 90px);
}

.leave-list-add-leave-button {
  width: fit-content;
  float: right;
}

/* Table  section */

.leave-list-table-container {
  margin-top: 20px;
  max-height: calc(100vh - 265px);
  overflow-y: auto;
}

.leave-list-table-container::-webkit-scrollbar {
  width: 2px;
}

.leave-list-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.leave-list-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.leave-list-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.leave-list-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 1;
}

.leave-list-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  border: none;
}

.leave-list-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.leave-list-table td {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  vertical-align: middle;
}

.leave-list-table th,
.leave-list-table td {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
}

.leave-list-table-lg-columns {
  display: none;
}

.leave-action {
  white-space: nowrap;
}

.leave-action>* {
  display: inline-block;
}

.leave-list-active {
  position: relative;
}

.leave-list-active::before {
  content: "";
  position: absolute;
  left: -11px;
  top: 0;
  width: 6px;
  height: 100%;
  background-color: rgba(63, 194, 138, 1);
  border-radius: 4px;
}

.leave-list-inactive {
  position: relative;
}

.leave-list-inactive::before {
  content: "";
  position: absolute;
  left: -11px;
  top: 0;
  width: 6px;
  height: 100%;
  background-color: rgba(226, 83, 25, 1);
  border-radius: 4px;
}

.leave-list-pending {
  position: relative;
}

.leave-list-pending::before {
  content: "";
  position: absolute;
  left: -11px;
  top: 0;
  width: 6px;
  height: 100%;
  background-color: rgba(246, 159, 58, 1);
  border-radius: 4px;
}

.leave-list-cancel {
  position: relative;
}

.leave-list-cancel::before {
  content: "";
  position: absolute;
  left: -11px;
  top: 0;
  width: 6px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.pagination {
  list-style: none;
  padding: 0;
  margin: 0;
  float: right;
}

.pagination li {
  display: inline-block;
  margin-right: 8px;
}

.pagination li button,
.pagination li span {
  border: none !important;
  background: none !important;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none !important;
}

.pagination>ul>li>button {
  padding: 0 !important;
  width: 31px !important;
  height: 32px !important;
  border-radius: 5px !important;
  background: none !important;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 5px 8px;
  font-weight: 300 !important;
  gap: 10px;
  border-radius: 8px;
}

.pagination>ul>li[aria-current="page"]>button {
  color: rgba(63, 194, 138, 1) !important;
  border: 1px solid rgba(63, 194, 138, 1) !important;
  text-align: center;
}

.leave-list-record-list>span {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.leave-list-record-list>select {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
}

@media screen and (max-width: 1024px) {
  .leave-list-select-holder {
    width: 50%;
  }

  .leave-list-dropdown-content {
    width: 19%;
  }
}

@media (min-width: 992px) {
  .leave-list-table-lg-columns {
    display: table-cell;
  }

  .leave-list-table-md-columns {
    display: none;
  }
}

@media (max-width: 991px) {
  .leave-list-table-md-columns {
    display: table-cell;
  }

  .leave-list-leave-employee-button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .leave-list-select-holder {
    width: 100%;
  }

  .leave-list-dropdown-content {
    width: 34%;
  }

  .leave-list-table td,
  th {
    padding-right: 10px;
  }

  .leave-list-add-employee-button {
    display: block;
    width: 100%;
  }

  .leave-list-leave-employee-button>button {
    width: 100%;
  }

  .leave-list-filter-input-container,
  .leave-list-filter-input-container>input {
    width: 100%;
    margin: 0 auto;
  }

  .column1 {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .leave-list-dropdown-content {
    width: 43%;
  }
}

.vl {
  border-left: 1px solid rgba(82, 83, 86, 1);
  height: 30px;
}

.seperator-of-leaves {
  border-left: 1px solid rgba(82, 83, 86, 1);
  height: auto;
  width: 2px;
  margin: 0 20px;
}

.leave-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.leave-cancel-bttn {
  width: 166px;
  height: 50px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1);
}

.leave-save-bttn {
  width: 166px;
  height: 50px;
  border-radius: 10px;
  border: rgba(63, 194, 138, 1);
  background-color: rgba(63, 194, 138, 1);
  font-weight: 500;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
  margin-left: 10px;
}

.half-day-label {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  margin-left: 10px;
}

.leave-date-range {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
}

.leave-date-range::placeholder {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
}

.leave-balance-type {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
}

.leave-duration {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
}

.leave-absence-type {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
}

.leave-start-day {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
}

.leave-comments {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
  height: 80px !important;
}

.leave-floating-label {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;

  padding-bottom: 20px;
  position: absolute;
  align-items: center;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.p-component {
  z-index: 1;
}

.p-inputwrapper {
  z-index: 1;
}

.p-datepicker-group-container {
  z-index: 8000 !important;
}

.MuiPaper-root {
  z-index: 7000;
}

.p-datepicker,
.p-ripple-disabled,
.p-connected-overlay-enter-done {
  z-index: 7000 !important;
}

.p-dropdown-label .p-inputtext {
  padding-top: 12px;
}

input[name="startDate"] {
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(162, 161, 168, 0.2);
  color: rgba(0, 0, 0, 1);
  padding-left: 10px;
}

input[name="startDate"]::placeholder {
  color: rgba(0, 0, 0, 1);

  font-weight: 300;
  font-size: 16px;
}

.writing-parameter-formik-error {
  color: red;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  position: absolute;
}

.p-dropdown-label {
  color: rgba(0, 0, 0, 1);
}

input:-internal-autofill-selected,
textarea:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -moz-box-shadow: 0 0 0 0 white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.leave-add-button {
  width: 176px;
  height: 40px;
  border-radius: 10px;
  background-color: rgba(63, 194, 138, 1);
  color: white;
  border: 1px solid rgba(63, 194, 138, 1);
}

.reportees-applied-leave-list-filter-input-container .form-control {
  width: 282px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #00000033;
  padding: 13px 16px 13px 16px;
  background-color: transparent;
}

.reportees-applied-leave-list-filter-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer !important;
  pointer-events: none;
}

.reportees-applied-leave-list-filter-input-container {
  width: 282px;
  position: relative;
}

.leave-select-date-range .p-float-label input {
  height: 48px;
  width: 100%;
  font-size: 16px;
  border: 1px solid rgba(162, 161, 168, 0.2);
  border-radius: 10px;
  background-color: #0000000d;
  color: black;
  padding-left: 10px;
  margin-left: none !important;
}

.leave-select-date-range .p-float-label label {
  position: absolute;
  top: 19px;
  left: 16px;
  font-size: 1rem;
  transition: 0.2s ease-out all;
  color: #808080;
  pointer-events: none;
}

.leave-select-date-range .p-component.p-inputwrapper .p-datepicker-trigger svg {
  margin-right: 16px;
}

.leave-container-fluid {
  border-bottom: 1px solid rgba(162, 161, 168, 0.2);
  padding-bottom: 5px;
}