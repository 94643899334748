.look-up-tab-details {
  width: 100%;
  padding-left: 20px;
}

.looup-holiday-year-filter>.p-component .p-inputtext {
  width: 132px;
  height: 40px;
  position: relative;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 20px;
  margin-top: -5px;
  border: 1px solid #00000033;
  border-radius: 10px;
  cursor: pointer;
}

.lookup-holiday-filter-icon {
  position: absolute;
  right: 15px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
  z-index: 1;
}