.p-float-employee-asset input {
    /* padding-right: 140px; */
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #0000000d;
    color: black;
    padding-left: 10px;
    margin-left: none !important;
    height: 30px !important;
}

.employee-asset-calendar .p-icon {
    margin-right: 45px;
    margin-top: -8px;
    /* position: absolute;
    top:0px;
    left: 0px;
    transform: translate(25px 28px); */
}

.employee-asset-calendar.filled-bg input:disabled {
    font-family: Lexend;
    font-size: 16px;
    height: 30px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 1) !important;
    border: 1px solid rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.add-employee-asset-input {
    width: 100%;
    font-size: 16px;
    height: 30px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    color: black;
    border: none;
    border-radius: 5px;
    text-wrap: wrap;
    object-fit: cover;
}

.add-employee-asset-input:focus {
    box-shadow: none;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

/* Trial */

.employee-asset-drop .asset-name-drop {
    width: 100%;
    height: 30px;
    border: 1px solid #00000033;
    border-radius: 5px;
}

.employee-asset-drop .asset-name-drop .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
    padding: 2px 12px;
}
.employee-asset-drop .asset-name-drop .p-icon.p-dropdown-clear-icon {
    top: 6px;
    right: 40px;
}


  
  /*Button  */

  .employee-add-asset {
    width: 130px;
    height: 36px;
    padding: 5px;
    border-radius: 5px;
    background: #3FC28A;
    color: #FFFFFF;
    border: none;
    font-family: "Lexend";
    font-size: 16px;
    font-weight: 500;
  }

.employee-asset-add-icon{
    margin-right: 10px;
    position: relative;
    bottom: 1px;
}