.Cancel-modal {
  width: 25%;
  margin: 0 auto;
  background-color: white;
  margin-top: 100px;
  border-radius: 10px;
  text-align: center;
  /* height: 50%; */
}

.modal-confirm-button {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;

  /* text-align: center; */
  color: #ffffff;
  background-color: rgba(244, 91, 105, 0.8);
  border-radius: 10px;
  padding: 6px;
  border: none;
  width: 90px;
  height: 45px;
}

.exclamation-icon {
  margin: 0 auto;
}

.sure-text {
  font-family: "Lexend", sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.cancelunsaved-text {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: center;

}

@media screen and (max-width: 930px) {
  .Cancel-modal {
    width: 35%;
    margin: 0 auto;
    background-color: white;
    margin-top: 100px;
    border-radius: 10px;
    text-align: center;
}
}