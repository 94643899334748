.employee-list-container {
  height: max-content;
}

.employee-list-banner-main {
  width: 97%;
  margin: 0 auto;
  margin-top: 2px;
}

.employee-list-header-text > h6 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000000;
}

.employee-list-header-text > p {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
}

.employeeList-header-Links {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  margin-right: 10px;
}

.employee-list-profile {
  height: 36px;
  width: 36px;
  border-radius: 50px;
  color: black;
  margin-right: 5px;
}

.employee-list-select-holder {
  height: fit-content;
}

.employee-list-select-holder:hover {
  cursor: pointer;
}

.employee-list-profile-holder > p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 1);
}

.employee-list-dropdown-content {
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 8px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  display: block;
  z-index: 1;
}

.employee-list-dropdown-content > a,
.employee-list-dropdown-content > div > a {
  text-decoration: none;
}

.employee-list-profile1 {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-wrap: nowrap;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 100px;
}

.employee-list-logout {
  color: rgba(226, 83, 25, 1);
  text-wrap: nowrap;
}

.employee-list-filter-input-container {
  width: 330px;
  position: relative;
}

.employee-list-filter-icon {
  position: absolute;
  top: 40%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer !important;
  pointer-events: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: #00000033;
}

.employee-list-table-holder-main {
  width: 100%;
  padding: 12px 20px 30px 20px;
  border-radius: 10px;
  border: 1px solid #00000033;
  height: calc(100vh - 80px);
}

.employee-list-add-employee-button {
  width: fit-content;
  float: right;
}

.employee-add-new {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  border-radius: 10px;
  padding: 8px 15px 8px 15px;
  border: none;
  /* width: 230px; */
  height: 40px;
}

.employee-export {
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  border-radius: 10px;
  padding: 6px 10px;
  border: none;
  /* width: 230px; */
  height: 36px;
}

/* /Table */

.employee-list-table-container {
  margin-top: 20px;
  max-height: calc(100vh - 230px);
  overflow-y: auto;
}

.employee-list-table-container::-webkit-scrollbar {
  width: 2px;
}

.employee-list-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.employee-list-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.employee-list-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.employee-list-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

.employee-list-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.employee-list-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding-bottom: 5px;
}

.employee-list-table td {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  vertical-align: middle;
}

.employee-list-table th,
.employee-list-table td {
  border-bottom: 1px solid #a2a1a833;
}

.employee-list-table-lg-columns {
  display: none;
}

.action {
  white-space: nowrap;
}

.action > * {
  display: inline-block;
}

.employee-list-active {
  background: #3fc28a33;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #3fc28a;
}

.employee-list-inactive {
  background: #e2531933;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #e25319;
}

/* Pagenation Section */
.pagination {
  padding: 0;
  margin: 0;
  float: right;
}

.pagination li {
  display: inline-block;
  margin-right: 8px;
}

.pagination li button,
.pagination li span {
  border: none !important;
  background: none !important;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none !important;
}

.pagination > ul > li > button {
  padding: 0 !important;
  width: 31px !important;
  height: 32px !important;
  border-radius: 5px !important;
  background: none !important;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 5px 8px;
  font-weight: 300 !important;
  gap: 10px;
  border-radius: 8px;
}

.pagination > ul > li[aria-current="page"] > button {
  color: rgba(63, 194, 138, 1) !important;
  border: 1px solid rgba(63, 194, 138, 1) !important;
  text-align: center;
}

.employee-list-record-list > span {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.employee-list-record-list > div > select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  width: 60px;
  height: 40px;
}

.employee-list-record-list > div > select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.employee-list-record-list-dropdown-icon {
  position: absolute;
  right: 6px;
  top: 8px;
  pointer-events: none;
}

.employee-list-filter-input-container .custom-dropdown {
  width: 330px;
  height: 40px;
  border-radius: 10px;
}

.employee-list-filter-input-container
  .custom-dropdown
  .p-icon.p-dropdown-clear-icon {
  top: 11px;
  right: 45px;
}

.employee-list-filter-input-container
  .custom-dropdown
  .p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  padding: 2px 12px;
}

/* .p-dropdown-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
min-width:"365px"!important;
} */

.p-dropdown:not(.p-disabled).p-focus {
  border-color: #00000033;
  box-shadow: none;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #00000033;
}

.p-dropdown-filter {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding-left: 10px;
}

.p-dropdown-panel > .p-dropdown-items-wrapper > .p-dropdown-items {
  padding-left: 15px;
}

.p-dropdown-filter-container {
  margin: 10px;
}

.p-icon.p-dropdown-filter-icon {
  display: inline-block;
  top: 11px;
  right: 15px;
}

/* .custom-dropdown .p-dropdown-trigger {
  display: flex;
  align-items: center;
   
} */

.p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 16px;
  font-weight: 300;
  padding: 5px 10px;
  letter-spacing: 0em;
  font-family: "Lexend";
  color: #00000080;
}

.employee-list-table-status {
  height: 24px;
  width: 95px;
  padding: 3px, 8px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
}

/* Media query */

@media (min-width: 992px) {
  .employee-list-table-lg-columns {
    display: table-cell;
  }

  .employee-list-table-md-columns {
    display: none;
  }
}

@media (max-width: 991px) {
  .employee-list-table-md-columns {
    display: table-cell;
  }

  .employee-list-add-employee-button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .employee-list-table td,
  th {
    padding-right: 10px;
  }

  .employee-list-add-employee-button,
  .employee-list-add-employee-button > button,
  .employee-list-filter-input-container,
  .employee-list-filter-input-container .custom-dropdown {
    width: 100%;
  }

  .employee-list-banner-main {
    margin-top: 10px;
  }

  .employee-list-table-container {
    margin-top: 15px;
    max-height: calc(100vh - 325px);
    overflow-y: auto;
  }

  .employee-list-table-holder-main {
    height: calc(100vh - 125px);
  }

  .employee-list-filter-input-container .custom-dropdown {
    width: 100%;
  }
}
.import-modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0px;
  padding: 20px;
  overflow-y: hidden;
}
.import-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* animation: slideIn 0.5s linear forwards; */
}

.import-modal-container > div {
  display: flex;
  justify-content: center;
  gap: 20px;
}
