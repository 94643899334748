.reportees-applied-leave-list-filter-input-container .form-control {
  width: 282px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #00000033;
  padding: 13px 16px 13px 16px;
  background-color: transparent;
}
.reportees-applied-leave-list-filter-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer !important;
  pointer-events: none;
}
.reportees-applied-leave-list-filter-input-container {
  width: 282px;
  position: relative;
}
.reportees-applied-leave-list-date-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
  z-index: 9999;
}
.applied-leave-cancel-bttn {
  width: 166px;
  height: 50px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  margin-left: 10px;
}
.applied-leave-approve-bttn {
  width: 166px;
  height: 50px;
  border-radius: 10px;
  border: rgba(63, 194, 138, 1);
  background-color: rgba(63, 194, 138, 1);
  font-weight: 500;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
  margin-left: 10px;
}
.applied-leave-reject-bttn {
  width: 166px;
  height: 50px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(244, 91, 105, 0.8);
  background-color: rgba(244, 91, 105, 0.8);
}
.applied-leave-label {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  margin-left: 8px;
}
.applied-leave-label-halfday {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  margin-left: 10px;
}
.applied-leave-comments {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
  /* padding-bottom: 20px; */                                                                                                                         
  height: 60px !important;
}
.applied-leave-floating-label {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 16px;

  padding-bottom: 20px;
}
.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root{
  padding-bottom: 4px;
}
.p-button:focus{
  box-shadow: none;
}