@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
* {
  font-family: "Lexend", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.add-button {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  border-radius: 10px;
  padding: 10px 10px;
  border: none;
}
.cancel-button {
  /* padding: 8px; */
  border-radius: 10px;
  border: 1px solid black;
  gap: 10px;
  background: red;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  height: 45px;
  width: 90px !important;
}

/* .app-right-side-content {
  margin-left: "5px" !important;
} */
.p-dropdown-panel > .p-dropdown-items-wrapper > .p-dropdown-items {
  padding: 0px 15px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 4px !important;
}

ul {
  margin: 10px 0px !important;
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  border-radius: 4px !important;
  padding: 1px 5px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  border-radius: 2px !important;
  padding: 1px 5px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 1px 5px !important;
}
