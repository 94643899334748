.timesheet-list {
  height: max-content;
}

.timesheet-list-container {
  width: 97%;
  margin: 0 auto;
  margin-top: 2px;
}

.timesheet-list-banner {
  width: 100%;
  padding: 12px 20px 30px 20px;
  border-radius: 10px;
  border: 1px solid #00000033;
  height: calc(100vh - 80px);
}

.timesheet-top-column {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.timesheet-list-filter .timesheet-status {
  width: 227px;
  height: 40px;
  border: 1px solid #00000033;
  border-radius: 10px;
}

.timesheet-list-filter .timesheet-status .p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  padding: 2px 12px;
}

.timesheet-list-filter .timesheet-status .p-icon.p-dropdown-clear-icon {
  top: 11px;
  right: 40px;
}

.timesheet-list-task-add .form-control::placeholder {
  font-family: "Lexend";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #00000080;
}

.timesheet-list-filter-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.timesheet-list-date-range input {
  width: 282px;
  height: 40px;
  border-radius: 10px;
  padding: 13px 16px;
  border: 1px solid #00000033;
  cursor: pointer;
}

.timesheet-list-date-range > input::placeholder {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #00000080;
}

.timesheet-list-date-range {
  position: relative;
  z-index: 1;
}

.timesheet-list-date-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
  z-index: 9999;
}

.timesheet-export-button {
  width: 117px;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
  background: #3fc28a;
  color: #ffffff;
  border: none;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
}

.timesheet-export-button-icon {
  margin-left: 10px;
  position: relative;
  bottom: 3px;
}

.timesheet-modal-add {
  width: 190px;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
  background: #3fc28a;
  color: #ffffff;
  border: none;
  font-family: "Lexend";
  font-size: 16px;
  font-weight: 500;
}

.timesheet-modal-add-iocn {
  margin-right: 10px;
  position: relative;
  bottom: 1px;
}

.timesheet-modal-title {
  font-family: "Lexend";
  font-size: 24px;
  font-weight: 600;
}

.timesheet-list-project-add .custom-project-dropdown {
  width: 100%;
  height: 50px;
  border-radius: 10px;
}

.timesheet-list-project-add
  .custom-project-dropdown
  .p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  padding: 2px 12px;
}

.timesheet-list-project-add
  .custom-project-dropdown
  .p-icon.p-dropdown-clear-icon {
  top: 17px;
  right: 40px;
}

.timesheet-list-top-container {
  background-color: #242c4433;
  height: 60px;
  width: 100%;
  margin-top: 10px;
}

.timesheet-list-top-column-2 {
  display: flex;
  padding: 10px 10px;
  border: none;
  gap: 1px;
}

.timesheet-list-task-add {
  position: relative;
}

.timesheet-list-task-add input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
}

.timesheet-list-task-add-icon {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-20px, 33px);
  pointer-events: none;
}

.timesheet-list-task-add-icon > svg {
  width: 34px;
  height: 34px;
  color: #a2a1a833;
}

.element.style {
  width: 100% !important;
  height: 50px !important;
  background: white !important;
}

.css-10o2lyd-MuiStack-root > .MuiTextField-root,
.css-10o2lyd-MuiStack-root > .MuiPickersTextField-root {
  min-width: 0px !important;
}

element.style {
  height: 100% !important;
}

.timesheet-date .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
  display: flex !important;
  background-color: white !important;
  height: 50px !important;
  margin-top: -7px !important;
  border: 1px solid #a2a1a833 !important;
  border-radius: 10px;
  width: 100% !important;
  padding-left: 8px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  font-family: Lexend !important;
}

.mobile-time .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px !important;
  display: flex !important;
  background-color: white !important;
  height: 50px !important;
  margin-top: -7px !important;
  border: 1px solid #a2a1a833 !important;
  border-radius: 10px;
  width: 100% !important;
  padding-left: 8px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  font-family: Lexend !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.timesheet-list-duration > input {
  font-family: "Lexend";
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  border-radius: 10px !important;
  border: 1px solid #a2a1a833;
}

.timesheet-list-duration > input::placeholder {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #00000080;
}

.timeseet-modal-lable {
  font-family: "Lexend";
  margin-left: 5px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #000000;
}

.timesheet-comment {
  margin-top: 15px;
}

.timesheet-comment > input {
  font-family: "Lexend";
  width: 100%;
  height: 80px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  border-radius: 10px !important;
  border: 1px solid #a2a1a833;
}

.timesheet-comment > input::placeholder {
  font-family: "Lexend";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #00000080;
}

.timesheet-modal-button {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.timesheet-list-add-button {
  width: 166px;
  height: 50px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: #3fc28a;
  color: #ffffff;
}

.timesheet-list-table-container {
  margin-top: 20px;
  max-height: calc(100vh - 295px);
  overflow-y: auto;
  padding-left: 25px;
}

.timesheet-list-table-container::-webkit-scrollbar {
  width: 2px;
}

.timesheet-list-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.timesheet-list-table-container::-webkit-scrollbar-thumb {
  background: #888;
}

.timesheet-list-table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.timesheet-list-table-container th {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

.timesheet-list-table {
  width: 100%;
  border-collapse: collapse;
}

.timesheet-list-table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.timesheet-list-table td {
  font-size: 16px;
  height: 47px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  vertical-align: middle;
  font-family: "Lexend";
}

.timesheet-list-table th,
.timesheet-list-table td {
  padding: 4px 10px 4px 0px;
  margin: 0;
  border-bottom: 1px solid #a2a1a833;
}

.timesheet-record-list > span {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #000000;
}

.timesheet-record-list > div > select {
  height: 40px;
  width: 60px;
  padding: 10px;
  border-radius: 8px;
  border: Mixed solid #00000033;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  cursor: pointer;
}

.timesheet-record-list > div > select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.timesheet-record-list-dropdown-icon {
  position: absolute;
  right: 6px;
  top: 8px;
  pointer-events: none;
}

.timesheet-pagination {
  padding: 0;
  margin: 0;
  float: right;
}

.timesheet-pagination li {
  display: inline-block;
  margin-right: 8px;
}

.timesheet-pagination li button,
.timesheet-pagination li span {
  border: none !important;
  background: none !important;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none !important;
}

.timesheet-pagination-container {
  height: 56px;
}

.timesheet-pagination > ul > li > button {
  padding: 0 !important;
  width: 31px !important;
  height: 32px !important;
  border-radius: 5px !important;
  background: none !important;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding: 5px 8px;
  font-weight: 300 !important;
  gap: 10px;
  border-radius: 8px;
}

.timesheet-pagination > ul > li[aria-current="page"] > button {
  color: rgba(63, 194, 138, 1) !important;
  border: 1px solid rgba(63, 194, 138, 1) !important;
  text-align: center;
}

.time-note {
  width: 100%;
}

.timesheet-add-modal > .col-lg-3 {
  width: 23%;
}

/* .timesheet-content-modal{
  height: max-content;
} */

@media only screen and (max-width: 1024px) {
  .TimesheetScrollContainer {
    overflow-x: scroll;
    position: relative;
  }

  .timesheet-list-top-container {
    width: fit-content;
    width: max-content;
  }

  .timesheetTableScroll {
    width: max-content;
  }

  .timesheet-list-banner {
    height: calc(100vh - 95px);
  }

  .timesheet-list-table-container {
    margin-top: 10px;
    max-height: calc(100vh - 310px);
    overflow-y: auto;
  }

  .timesheet-list-table-container th {
    top: -11px;
  }
}

@media (max-width: 995px) {
  .timesheet-top-column {
    max-width: 100%;
    display: block;
    margin: auto;
  }

  .timesheet-list-filter,
  .timesheet-list-date-range,
  .timesheet-export-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .timesheet-list-filter .timesheet-status {
    width: 100%;
  }

  .timesheet-list-date-range input {
    width: 100vw;
  }

  .timesheet-list-table {
    width: 100%;
    border-collapse: collapse;
  }

  .timesheet-list-table-container {
    margin-left: 0px;
  }

  .timesheet-list-banner {
    height: calc(100vh - 110px);
  }

  .timesheet-list-table-container {
    margin-top: 10px;
    max-height: calc(100vh - 440px);
    overflow-y: auto;
  }

  .timesheet-list-table-container th {
    top: -13px;
  }

  .timesheet-add-modal > .col-lg-3,
  .timesheet-add-modal > .col-lg-2 {
    width: 100%;
  }

  .timesheet-add-modal {
    display: grid !important;
  }

  .timesheet-container {
    overflow-y: auto;
    max-height: 600px;
  }
}

.css-sx5hge > .MuiTextField-root,
.css-sx5hge > .MuiPickersTextField-root {
  min-width: 100px !important;
}

.css-1uvydh2 {
  padding: 0.375rem 0.75rem !important;
}

.css-1bn53lx {
  height: 50px !important;
  border-radius: 10px !important;
}
