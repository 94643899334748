.Employee-document-filename {

    font-family: Lexend;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    /* padding: 10px 10px 10px 20px; */
    color: #000000;
}

.document-file-container>.document-choose {
    border: 1px solid #A2A1A833;
    height: 40px;
    padding: 6px 0px 0px 8px;
    border-radius: 10px 0px 0px 10px;
    border-right: none;
    width: 30%;
}

.document-file-container>.document-image {
    border: 1px solid #A2A1A833;
    height: 40px;
    padding: 6px 0px 0px 8px;
    border-radius: 0px 10px 10px 0px;
    border-left: none;
    width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;

}

.document-file-container {
    width: 100%;
    display: flex;
    white-space: nowrap;
    cursor: pointer;
}

.document-file-container span:first-child {
    border-right: 1px solid #A2A1A833;
}


.employee-document-data .dropzone-data>span {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: #000000;
}

.document-view-eye-icon {
    display: flex;
    align-items: center;
    padding: 0px 12px;
}
.upload-files-main-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.upload-files-outer-container{
    display: flex;
    flex-direction: column;
    gap:15px
}
.error-message-text-document {
    color: red;
    font-size: 13px;
    padding-left: 10px;
    left: 10px;
    top: 50px;
  }

  
.lds-ring {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 2px;
    border: 2px solid #3fc28a;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3fc28a transparent transparent transparent;
  }
  
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  