.reportees-navbar {
  border-radius: 10px;
  /* opacity: 0px; */
  width: 98.5%;
  margin: 0 auto;
  margin-top: 2px;
  border: 1px solid #00000033;
  height: calc(100vh - 80px);
}

.reportees-navbar-text {
  color: black !important;
  text-decoration: none !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  font-weight: 300;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 0;
  text-wrap: nowrap;
  margin-right: 10px;
  padding-bottom: 5px;
  margin-bottom: 0;
  cursor: pointer;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler {
  border-radius: 0;
  border: 1px solid black;
  padding: 10px;
}

.reportees-navbar-text:hover,
.reportees-navbar-text.active {
  transition: all 0.5 ease-in;
  color: rgba(63, 194, 138, 1) !important;
  text-decoration: none !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  border-bottom: 3px solid rgba(63, 194, 138, 1);
}

.reportees-navbar-text-active {
  transition: all 0.5 ease-in;
  color: rgba(63, 194, 138, 1) !important;
  text-decoration: none !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  border-bottom: 3px solid rgba(63, 194, 138, 1);
}

.AllEmployeenav-icons {
  margin-right: 7px;
}

.reportees-navibar-container {
  padding: 0px;
  width: 100%;
  background-color: none !important;
}

.AllEmployeenav-icons svg {
  width: 24px;
  height: 24px;
}

.reportees-navbar-text:hover>.hover-hide-logo {
  display: none;
}

.hover-show-logo {
  display: none;
  width: 30px;
}

.hover-hide-logo {
  width: 30px;
}

.reportees-navbar-text:hover>.hover-show-logo {
  display: block;
}

.reportees-navbar-text-active .hover-hide-logo {
  display: none;
}

.reportees-navbar-text-active .hover-show-logo {
  display: block;
}

.navbar-toggler {
  background-color: white;
  outline: none;
  border: 1px solid #666;
  border-radius: 10px;
}

.navbar-toggler-icon {
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px;
}

.sticky-header {
  position: sticky;
  top: 76px;
  left: 50px;
  z-index: 1;
  background-color: #ffffff;
}

.reportees-navbar-text:active {
  color: green;
}

.monthly-leave-update {
  font-size: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  background-color: #3fc28a;
  border-radius: 10px;
  padding: 6px 20px;
  border: none;
  width: fit-content;
  text-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  .reportees-navbar-text {
    margin-top: 10px;
    padding-left: 8px;
  }

  .reportees-navbar-text:hover {
    margin-top: 10px;
    border-bottom: none;
    border-left: 3px solid rgba(63, 194, 138, 1);
  }

  .reportees-navbar-text-active {
    margin-top: 10px;
    border-bottom: none;
    border-left: 3px solid rgba(63, 194, 138, 1);
  }

  .reportees-tab-details {
    margin-top: 50px !important;
  }

  .sticky-header {
    position: sticky;
    top: 60px;
    left: 50px;
    z-index: 1;
    background-color: #ffffff;
  }

  .reportees-list-container {
    margin-top: -10px !important;
  }
}

.reportees-tab-details {
  width: 100%;
  padding-left: 20px;
  margin-top: -20px !important;
}